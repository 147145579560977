import axios from 'axios';
import { useEffect, useState } from 'react';

// Predefined translations for critical content
const MANUAL_TRANSLATIONS = {
  fr: {
    title: "Politique de Confidentialité",
    description: "Politique de confidentialité expliquant comment nous gérons les données utilisateur.",
    sections: {
      dataCollection: "Collecte de Données",
      userRights: "Droits des Utilisateurs",
      dataProtection: "Protection des Données"
    }
  },
  es: {
    title: "Política de Privacidad",
    description: "Política de privacidad que explica cómo manejamos los datos del usuario.",
    sections: {
      dataCollection: "Recopilación de Datos",
      userRights: "Derechos del Usuario",
      dataProtection: "Protección de Datos"
    }
  }
  // Add more manual translations as needed
};

// Translation function with multiple fallback mechanisms
async function translateText(text, targetLanguage) {
  // 1. Check manual translations first
  if (MANUAL_TRANSLATIONS[targetLanguage]) {
    return text; // Use manual translation for critical content
  }

  // 2. Try public LibreTranslate instances
  const PUBLIC_INSTANCES = [
    'https://libretranslate.com',
    'https://translate.argocd.pro',
    'https://libretranslate.de'
  ];

  for (const baseUrl of PUBLIC_INSTANCES) {
    try {
      const response = await axios.post(`${baseUrl}/translate`, {
        q: text,
        source: 'en',
        target: targetLanguage,
        format: 'text'
      }, {
        // Add timeout and error handling
        timeout: 5000,
        validateStatus: function (status) {
          return status >= 200 && status < 300;
        }
      });

      return response.data.translatedText;
    } catch (error) {
      console.warn(`Translation failed with ${baseUrl}`, error);
    }
  }

  // 3. Fallback to original text
  return text;
}

// React Component
function PrivacyTest() {
  const [translatedContent, setTranslatedContent] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const targetLang = urlParams.get('lang') || 'en';

    // Use manual translations for predefined languages
    if (MANUAL_TRANSLATIONS[targetLang]) {
      setTranslatedContent(MANUAL_TRANSLATIONS[targetLang]);
      return;
    }

    // Original content
    const originalContent = {
      title: "Privacy Policy",
      description: "This is our privacy policy explaining how we handle user data.",
      sections: {
        dataCollection: "Data Collection",
        userRights: "User Rights",
        dataProtection: "Data Protection"
      }
    };

    // Translate content
    async function loadTranslations() {
      const translatedTitle = await translateText(originalContent.title, targetLang);
      const translatedDescription = await translateText(originalContent.description, targetLang);

      const translatedSections = {};
      for (const [key, section] of Object.entries(originalContent.sections)) {
        translatedSections[key] = await translateText(section, targetLang);
      }

      setTranslatedContent({
        title: translatedTitle,
        description: translatedDescription,
        sections: translatedSections
      });
    }

    // Only translate if language is different from source
    if (targetLang !== 'en') {
      loadTranslations();
    } else {
      setTranslatedContent(originalContent);
    }
  }, []);

  return (
    <div>
      <h1>{translatedContent.title}</h1>
      <p>{translatedContent.description}</p>
      {translatedContent.sections && Object.entries(translatedContent.sections).map(([key, section]) => (
        <h2 key={key}>{section}</h2>
      ))}
    </div>
  );
}

export default PrivacyTest;