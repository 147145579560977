import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}



export const getDayLabel = (date) => {
    const day = new Date(date).getDay();
    switch (day) {
        case 0:
            return 'S'; // Sunday
        case 1:
            return 'M'; // Monday
        case 2:
            return 'T'; // Tuesday
        case 3:
            return 'W'; // Wednesday
        case 4:
            return 'T'; // Thursday
        case 5:
            return 'F'; // Friday
        case 6:
            return 'S'; // Saturday
        default:
            return '';
    }
};

export const getDayNumber = (date) => {
    const dayNumber = new Date(date).getDate();
    return String(dayNumber);
};

export const processFillWeightData = (weightData, startDate, endDate) => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Create a map for quick lookups of weight data by date
        const dateMap = new Map(weightData.map(item => [item.date, item]));
        const filledWeightData = [];

        // Iterate over each date between startDate and endDate
        for (let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = currentDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            const existingData = dateMap.get(formattedDate);

            filledWeightData.push({
                date: formattedDate,
                goal_weight: existingData?.goal_weight || 0,
                unit: existingData?.unit || 'lbs',
                weight: existingData?.weight || 0,
            });
        }

        // Format the data
        return filledWeightData.map(item => ({
            date: item.date,
            value: Math.round(item.weight),
        }));
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const processFillHeartData = (heartData, startDate, endDate) => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const dateMap = new Map(heartData.map(item => [item.date, item]));
        const filledHeartData = [];

        for (let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            const existingData = dateMap.get(formattedDate);

            filledHeartData.push({
                date: formattedDate,
                value: existingData?.average_heart_rate || 0
            });
        }

        return filledHeartData;
    } catch (error) {
        console.error(error);
        return [];
    }
};


export const processFillSleepData = (sleepData, startDate, endDate) => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const dateMap = new Map(sleepData.map(item => [item.date, item]));
        const filledSleepData = [];

        for (let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            const existingData = dateMap.get(formattedDate);

            const averageSleepInHours = (existingData?.average_sleep || 0) / 3600; // Convert seconds to hours
            const duration = `${Math.floor(averageSleepInHours)}h ${Math.round((averageSleepInHours % 1) * 60)}m`;

            filledSleepData.push({
                date: formattedDate,
                value: existingData?.average_sleep || 0,
                duration: existingData ? duration : "0h 0m",
            });
        }

        return filledSleepData;
    } catch (error) {
        console.error(error);
        return [];
    }
};


export const processFillStepsData = (stepsData, startDate, endDate) => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const dateMap = new Map(stepsData.map(item => [item.date, item]));
        const filledStepsData = [];

        for (let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            const existingData = dateMap.get(formattedDate);

            filledStepsData.push({
                date: formattedDate,
                value: existingData?.total_steps || 0
            });
        }

        return filledStepsData;
    } catch (error) {
        console.error(error);
        return [];
    }
};
export const handleBarToolTip = (src, value) => {
    switch (src) {
        case "sleep":
            return value === 0 ? "no record" : `${Math.floor(value / 3600)}h ${Math.round((value % 3600) / 60)}m`;
        case "step":
            console.log("stepsss");
            return value === 0 ? "no record" : `${Math.round(value || 0)} Steps`;
        default:
            return value
    }
}



export const formatSleep = (seconds) => {
    // Check if the duration exceeds 18 hours (or 20 hours), return "N/A" in that case
    if (seconds >= 18 * 3600 || seconds >= 20 * 3600) {
        return 'N/A';
    }

    const hours = Math.floor(seconds / 3600); // Convert seconds to hours
    const minutes = Math.floor((seconds % 3600) / 60); // Get remaining minutes after hours are accounted for

    if (hours === 0 && minutes === 0) {
        return `N/A`;
    }

    // If there are no minutes, just return the hours
    if (minutes === 0) {
        return `${hours}h`;
    }

    if (hours === 0) {
        return `${minutes}min`;
    }

    // Otherwise, return hours and minutes
    return `${hours}h ${minutes}min`;
};




// ====================>>
export const checkAllZero = (data, keyName) => {
    try {
        // Check if data is valid (not null, undefined, or empty)
        if (!data || data.length === 0) {
            return true;
        }

        // Check if all values for the given key are zero
        return data?.every(item => item[keyName] === 0);
    } catch (error) {
        return true;
    }
};