import { useMemo, lazy, Suspense } from "react";
import { checkAllZero } from "../lib/utils";

const CaloricChart = lazy(() => import("./report_cards/calorie-chart"));
const StepsChart = lazy(() => import("./report_cards/steps-chart"));
const WeightChart = lazy(() => import("./report_cards/weight-chart"));
const HeartRateChart = lazy(() => import("./report_cards/heart-charts"));
const SleepChart = lazy(() => import("./report_cards/sleep-charts"));

const CustomCharts = ({ data }) => {
    const {
        weight_data,
        step_data,
        heart_data,
        calorie_data,
        sleep_data,
        report_date
    } = useMemo(() => ({
        weight_data: data?.report_data?.weight_data ?? [],
        step_data: data?.report_data?.steps_data ?? [],
        heart_data: data?.report_data?.heart_data ?? [],
        calorie_data: data?.report_data?.diet_data ?? [],
        sleep_data: data?.report_data?.sleep_data ?? [],
        report_date: data?.report_data?.date_date
    }), [data]);


    // Tailwind-based loader skeleton
    const LoaderSkeleton = () => (
        <div className="animate-pulse space-y-4">
            <div className="h-6 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
    );

    // Conditional rendering of chart components
    const renderChart = (Component, chartData, props = {}) => {
        if (!chartData) {
            return (
                <div className="p-4 border rounded shadow-md">
                    <LoaderSkeleton />
                </div>
            );
        }
        return (
            <Suspense fallback={<LoaderSkeleton />}>
                <Component data={chartData} {...props} />
            </Suspense>
        );
    };



    const isWeightZero = checkAllZero(weight_data?.weight_data, "weight");
    const isStepZero = checkAllZero(step_data?.daily_steps, "total_steps");
    const isHeartZero = checkAllZero(heart_data?.daily_heart_rate, "average_heart_rate");
    const isSleepZero = checkAllZero(sleep_data?.daily_sleep, 'average_sleep');

    const isCalorieZero = useMemo(() => {
        return calorie_data?.weekly_macros?.every(entry =>
            entry?.taken_calories === 0 &&
            entry?.taken_carbs === 0 &&
            entry?.taken_fats === 0 &&
            entry?.taken_proteins === 0
        );
    }, [calorie_data]);



    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 sm:gap-5 gap-3">
            {!isCalorieZero && renderChart(CaloricChart, calorie_data)}
            {!isStepZero && renderChart(StepsChart, step_data, { date: report_date })}
            {!isWeightZero && renderChart(WeightChart, weight_data, { date: report_date })}
            {!isHeartZero && renderChart(HeartRateChart, heart_data, { date: report_date })}
            {!isSleepZero && renderChart(SleepChart, sleep_data, { date: report_date })}
        </div>
    );
};

export default CustomCharts;
