import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CustomCharts from "./components/custom-charts";
import './global.css';
import { AlertCircle } from "lucide-react";
// import '../fitness-workouts.css';
// import './fitness-report.css';

const FitnessReport = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);

    const fetchDetails = useCallback(async () => {
        try {
            const { data: responseData } = await axios.get(`https://myfitnesscoach.info/getReport/share`, {
                params: { report_id: id },
            });
            if (responseData?.status === 400) {
                setData(null);
            } else {
                setData(responseData);
            }

        } catch (error) {
            console.log("--->>>> error");
            setData(null)
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            fetchDetails();
        }
    }, [id, fetchDetails]);

    const formatDate = (startDate, endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const options = { month: "short", day: "2-digit" }; // Formatting options
        return `${start.toLocaleDateString("en-US", options)} - ${end.getDate()}, ${end.getFullYear()}`;
    };

    const formattedDate = data?.report_data?.date_date ? formatDate(data?.report_data?.date_date?.start_date, data?.report_data?.date_date?.end_date) : "";

    return (
        <div className="w-full min-h-screen" id="fitness-report">
            <header className="flex justify-between items-center border-b bg-white mx-auto px-2 sm:px-4 py-2 sm:py-6">
                <div className="flex items-center gap-4 container">
                    <img src="../../assets/nav-logo-sm.png" alt="My Fitness Coach Logo" className="w-8 h-8 sm:w-12 sm:h-12" />
                    <p className="text-base sm:text-xl font-bold">My Fitness Coach</p>
                </div>
            </header>


            {data ? (
                <div className="container mx-auto px-2 py-4  sm:px-4 sm:py-6">

                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <h4 className="text-lg sm:text-2xl font-semibold text-gray-800">Share Health Reports with Your Friends</h4>
                        <p className="text-sm sm:text-lg text-gray-600 mt-2">Download MyFitnessCoach now to see your daily health reports.</p>
                        <div className="flex justify-start gap-4 mt-4">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.myfitnesscoach"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2"
                            >
                                <img src="../assets/google-store-btn.svg" alt="Get it on Google Play Store" className="h-10" />
                            </a>
                            <a
                                href="https://apps.apple.com/pk/app/myfitnesscoach-diet-workout/id1670136195"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center gap-2"
                            >
                                <img src="../assets/app-store-btn.svg" alt="Get it on App Store" className="h-10" />
                            </a>
                        </div>
                    </div>


                    <div className="grid lg:grid-cols-2 gap-6 mt-6 bg-white">
                        <div className="lg:col-span-2 bg-white rounded-lg shadow-sm p-3 sm:p-6">
                            <h2 className="text-lg sm:text-3xl font-bold text-gray-800">Weekly Report</h2>
                            <div className="flex justify-between mt-2">
                                <p className="text-gray-600 sm:text-lg text-sm">by {data?.report_data?.username ?? "Unknown"}</p>
                                <p className="text-gray-600 sm:text-lg text-sm">{formattedDate}</p>
                            </div>

                            <div className="mt-6">
                                {data ? (
                                    <CustomCharts data={data} />
                                ) : (
                                    <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-md">
                                        <p className="text-center">No data available to display charts. Please check your reportID or try again later.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center bg-red-100 border border-red-400 text-red-700 rounded-md min-h-screen">
                    <AlertCircle className="w-12 h-12 mb-4" />
                    <p className="text-xl font-semibold">No Data Available</p>
                    <p className="text-center mt-2">Unable to display charts. Please check your report ID or try again later.</p>
                </div>
            )}

        </div>
    );
};

export default FitnessReport;


